import axios from "axios";
//export const apiUrl = "http://localhost:8080/api/";
export const apiUrl = "https://dmtclubapp.com/api/";
export const guestRegister = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}register`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const otpVerification = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}otpMatch`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getTestonomials = async () => {
  try {
    const data = await axios.get(`${apiUrl}testinomials`);

    return data.data;
  } catch (e) {
    return [];
  }
};

export const getLevelTeam = async (address, level, page, packageId) => {
  try {
    const data = await axios.post(`${apiUrl}get-level-team`, {
      address,
      level,
      page,
      packageId
    });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};
export const getLevelTeamPos = async (address, level, page, packageId) => {
  try {
    const data = await axios.post(`${apiUrl}get-level-team-position`, {
      address,
      level,
      page,
      packageId
    });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getMailData = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}checkemail`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getNewLevelData = async (formData) => {
  console.log(formData, "userData:::")
  try {
    const data = await axios.post(`${apiUrl}levelwiserecord`, formData);

    return data.data;
  } catch (e) {
    return [];
  }
};
export const updateExpectedMember = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}update-expected-member`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const checkrewardCriteria = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}reward-check`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return false;
  }
};

export const expDonationList = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}expected-donation-list`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const checkwalletExist = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}wallet-exist`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const forgetPassword = async (formData) => {
  try {
    const data = await axios.post(`${apiUrl}forget-password`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const changePassword = async (formData) => {
  try {
    const data = await axios.post(`${apiUrl}change-password`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const myDonation = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}donation-list`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const chatUsers = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}chat-users`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const sendMessageApi = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}send-message`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const markRead = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}mark-as-read`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const chatHistory = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}chat-history`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const loginmail = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}login`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getTimer = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}remainingtime`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const detailByEmail = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}detailbyeamil`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};
export const getDownlineTeam = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}uplines`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};
export const getRewardTimer = async (formData) => {
  try {

    const data = await axios.post(`${apiUrl}remainingtimereward`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getUserDetails = async (userId) => {
  try {

    const data = await axios.post(`${apiUrl}userDetails`, { userId });

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getAllUserId = async (email) => {
  try {

    const data = await axios.post(`${apiUrl}getUserId`, { email });

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getAllPackages = async (userId) => {
  try {

    const data = await axios.post(`${apiUrl}getallPackage`, { userId });

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getTreeView = async (walletAddress, packageId) => {
  try {
    const data = await axios.post(`${apiUrl}directmemberplace`, {
      walletAddress, packageId
    });

    return data.data;
  } catch (e) {
    return [];
  }
};

export const getUserDetailsByWallet = async (walletAddress, packageId) => {
  try {
    const data = await axios.post(`${apiUrl}userDetailsbyWallet`, {
      walletAddress, packageId
    });

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getDirectMember = async (walletAddress, packageId) => {
  try {

    const data = await axios.post(`${apiUrl}directmember`, { walletAddress, packageId });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getStakingHistory = async (wallet_address) => {
  try {
    const data = await axios.post(`${apiUrl}get-stake-history`, {
      wallet_address,
    });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getDashBoardDetails = async (formData) => {
  try {
    const data = await axios.post(`${apiUrl}dashboarddetails`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getLevelHistory = async (walletAddress, sortby, page, packageId) => {
  try {
    const data = await axios.post(`${apiUrl}get-level-stack`, {
      walletAddress,
      sortby,
      page,
      packageId
    });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getUserTeam = async (wallet_address, packageId) => {
  try {
    const data = await axios.post(`${apiUrl}downlineteam`, { wallet_address, packageId });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getRewardData = async (formData) => {
  try {
    const data = await axios.post(`${apiUrl}reward-history`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getRangeData = async (formData) => {
  try {
    const data = await axios.post(`${apiUrl}get-add-range`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const deleteRange = async (formData) => {
  try {
    const data = await axios.post(`${apiUrl}delete-range`, formData);
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getLevelRegistration = async (walletAddress, sortby, page, packageId) => {

  try {

    const data = await axios.post(`${apiUrl}get-level-registration`, {
      walletAddress,
      sortby,
      page,
      packageId
    });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getLevelSponser = async (walletAddress, sortby, page) => {
  try {
    const data = await axios.post(`${apiUrl}get-level-sponsor`, {
      walletAddress,
      sortby,
      page,
    });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const withDraw = async (amount, wallet_address) => {
  try {
    const data = await axios.post(`${apiUrl}withdrawworking`, {
      amount,
      wallet_address,
    });
    console.log(data, "resApi::::");
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const withDrawList = async (walletAddress) => {
  try {
    const data = await axios.post(`${apiUrl}withdraws`, { walletAddress });
    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getDirectTeam = async (userAddress, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}referral/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export async function getQrcode(fdata) {
  try {
    const data = await axios.post(`${apiUrl}qr-code`, fdata);
    return data.data;
   
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function verify2fa(fdata) {
  try {
    const data = await axios.post(`${apiUrl}verify-2fa-otp`, fdata);
    return data.data;
   
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
}

// export async function update2faStatus(email, status) {
//   try {
//     const tokenData = JSON.parse(localStorage.getItem("token"));
//     const { token } = tokenData;
//     const response = await axios.post(
//       `${Base_url}/user/update-2faStatus`,
//       { email, status },
//       {
//         headers: {
//           Authorization: `${token}`,
//         },
//       }
//     );

//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//     throw error; // Rethrow the error to handle it at the calling site
//   }
// }

export async function check2faStatus(fdata) {
  try {

    const data = await axios.post(`${apiUrl}2fa-status`, fdata);
    return data.data;
   
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
}
export async function updateProfile(fdata) {
  try {

    const data = await axios.post(`${apiUrl}update-profile`, fdata);
    return data.data;
   
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
}


export async function getProfileDetails(fdata) {
  try {

    const data = await axios.post(`${apiUrl}get-profile`, fdata);
    return data.data;
   
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
}



export async function updateNewPassword(fdata) {
  try {

    const data = await axios.post(`${apiUrl}change-new-password`, fdata);
    return data.data;
   
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
}




