import React, { Fragment, useEffect, useReducer, useState } from "react";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import profile01 from "../../images/CLUB.png";
import { check2faStatus, getProfileDetails, getQrcode, updateNewPassword, updateProfile, verify2fa } from "../../helpers/api";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from React Icons

import { FaUserEdit } from 'react-icons/fa'; // Import the edit icon from React Icons

import { MdOutlinePassword } from "react-icons/md";



const Profile = () => {
	const [name, setName] = useState("");
	const [email2, setEmail2] = useState("");
	const [mobile, setMobile] = useState("");
	const [userData, setUserData] = useState('')
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);


	const [otp, setOtp] = useState("");
	const [isVerified, setIsVerified] = useState(false);
	const [faStatus, set2faStatus] = useState(false);
	const handleVerify2FA = () => {
		if (otp) {
			const fData = {
				email: email,
				otp: otp
			}
			verify2fa(fData).then((res) => {
				if (res.success) {
					setIsVerified(true);
					localStorage.setItem("2faEnabled", "true");
					toast.success("2FA Enabled Successfully")
				} else {
					toast.error("authentication failed");
				}
			});

		} else {
			toast.error("Enter 2fa code !");
		}
	};

	const [qrCode, setQrcode] = useState("");
	const userDetails = JSON.parse(localStorage.getItem("userDetails"));
	const email = userDetails?.email;
	const userId = userDetails?.userId;
	useEffect(() => {
		const fetchQrCode = async () => {
			try {
				const data = { email };
				const res = await getQrcode(data);
				setQrcode(res);
			} catch (error) {
				console.error("Error fetching QR code:", error);
			}
		};
		const checkStatus = async () => {
			try {
				const data = { userId };
				const res = await check2faStatus(data);
				set2faStatus(res.twoFactorEnabled);
			} catch (error) {
				console.error("Error fetching QR code:", error);
			}
		};

		const getprofileData = async () => {
			try {
				const data = { email, userId };
				const res = await getProfileDetails(data);

				setName(res?.user.name);
				setEmail2(res?.user.email2);
				setMobile(res?.user.mobile);
			} catch (error) {
				console.error("Error fetching QR code:", error);
			}
		};

		if (email) {
			fetchQrCode();
			checkStatus()
			getprofileData()
		}
	}, [email]);
	function updateProfileDetails() {

		let fdata = {
			email: email,
			email2, email2,
			mobile: mobile,
			name: name,
			userId: userId
		}

		updateProfile(fdata).then((res) => {
			if (res.status) {
				toast.success(res?.message)

			}else{
				toast.error(res?.message)

			}


		})

	}
	function updatePassword() {

		let fdata = {
			email: email,
			newPassword:newPassword,
			currentPassword:currentPassword
		}

		updateNewPassword(fdata).then((res) => {
			if (res.status) {
				toast.success(res?.message)

			}else{
				toast.error(res?.message)

			}

		})

	}
	return (
		<Fragment>

			<div className="row">
				<div className="col-lg-12">
					<div className="profile card card-body px-3 pt-3 pb-0">
						<div className="profile-head">
							<div className="photo-content ">
								<div className="cover-photo rounded"></div>
							</div>
							<div className="profile-info">
								<div className="profile-photo">
									<img src={profile01} className="img-fluid rounded-circle" alt="profile" />
								</div>
								<div className="profile-details">
									<div className="profile-name px-3 pt-2">
										<h4 className="text-primary mb-0">{userId}</h4>
										<p>User ID</p>
									</div>
									<div className="profile-email px-2 pt-2">
										<h4 className="text-muted mb-0">{email}</h4>
										<p>Email</p>
									</div>


								</div>
							</div>

							<div className=" pt-1">
								<h3 className="ms-2 text-center">Add Details <FaUserEdit
									className="ms-2 mt-0"


								/></h3>


							</div>
							<div className="row">
								<div className="col-md-4">

									<div className="text-start pt-1">
										<label
											htmlFor="exampleFormControlInput1"
											className="form-label "
										>
											Name *
										</label>
										<input
											type="text"
											className="form-control custom-form"
											placeholder="Name"
											value={userData?.name ? userData?.name : name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-md-4">

									<div className="text-start pt-1">
										<label
											htmlFor="exampleFormControlInput1"
											className="form-label "
										>
											Secondary Email *
										</label>
										<input
											type="email"
											className="form-control custom-form"
											placeholder="Email"
											value={userData?.email2 ? userData?.email2 : email2}
											onChange={(e) => setEmail2(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-md-4">

									<div className="text-start pt-1">
										<label
											htmlFor="exampleFormControlInput1"
											className="form-label "
										>
											Mobile Number *
										</label>
										<input
											type="mobile"
											className="form-control custom-form"
											placeholder="Mobile Number"
											value={userData?.mobile ? userData?.mobile : mobile}

											onChange={(e) => setMobile(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="text-center">
								<button
									className="btn btn-success enable-button fs-13 mt-5 pt-2 pb-2 mb-5 "
									onClick={() => {
										updateProfileDetails()
									}}
								>
									Submit
								</button>
							</div>

							<h3 className="pt-1 ms-2 text-center">Change Password 
							<MdOutlinePassword />
							</h3>
							<div>
								<div className="row">
									<div className="col-md-6">
										<div className="text-start pt-1 position-relative">
											<label htmlFor="currentPassword" className="form-label">
												Current Password *
											</label>
											<div className="position-relative">
												<input
													type={showCurrentPassword ? 'text' : 'password'}
													className="form-control custom-form pe-5"
													placeholder="Enter Current Password"
													value={currentPassword}
													onChange={(e) => setCurrentPassword(e.target.value)}
												/>
												<span
													className="position-absolute end-0 top-50 translate-middle-y me-3"
													style={{ cursor: 'pointer' }}
													onClick={() => setShowCurrentPassword(!showCurrentPassword)}
												>
													{showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
												</span>
											</div>
										</div>
									</div>

									<div className="col-md-6">
										<div className="text-start pt-1 position-relative">
											<label htmlFor="newPassword" className="form-label">
												New Password *
											</label>
											<div className="position-relative">
												<input
													type={showNewPassword ? 'text' : 'password'}
													className="form-control custom-form pe-5"
													placeholder="Enter New Password"
													value={newPassword}
													onChange={(e) => setNewPassword(e.target.value)}
												/>
												<span
													className="position-absolute end-0 top-50 translate-middle-y me-3"
													style={{ cursor: 'pointer' }}
													onClick={() => setShowNewPassword(!showNewPassword)}
												>
													{showNewPassword ? <FaEyeSlash /> : <FaEye />}
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="text-center">
									<button
										className="btn btn-success enable-button fs-13 mt-5 pt-2 pb-2 mb-5"
										onClick={updatePassword}
									>
										Submit
									</button>
								</div>
							</div>

						</div>


					</div>
				</div>
			</div>
			{/* <div className=" d-flex justify-content-center">

				<div className="col-lg-6">
					<div className="card">

						<div className="card-body pt-3"	>
							<div className="profile-blog ">

								<h3 className="pt-1 ms-2 text-center">Two-Factor Authentication</h3>


								{faStatus ?

									<div className="text-center">
										<FaCheckCircle style={{ color: "green", fontSize: "84px" }} />
										<p className="pt-2 fw-bolder"> 2FA ENABLED</p>

									</div>
									:
									<>

										<p className="pt-1 text-center">
											The first step is to download the Google Authenticator app for
											your Android or iOS device. If you need help getting started,
											please see
											<span className="text-success"> Google's Support Page.</span>
										</p>
										<div className="qr-code-container ">
											<div className="text-center text-dark pt-2">
												Scan the QR Code
											</div>

											<div className="pt-2 text-center">
												<div>
													<img
														src={`${qrCode?.dataURL}`}
														alt="QR Code"
														className="img-fluid"
														height={250}
														width={250}
													/>
												</div>
												<p style={{ fontSize: "9px" }} className="mb-2 ">{qrCode?.secret}</p>
											</div>
										</div>
										<div className="stablecoin-para pt-2">
											The token will not be shown again after 2FA is enabled. If you
											have multiple devices, add your account token to all of them
											before clicking enable. (Note: Your Account Token will change each
											time you reload your browser.)
										</div>
										<div className="text-start pt-1">
											<label
												htmlFor="exampleFormControlInput1"
												className="form-label text-warning"
											>
												Google Authenticator Code *
											</label>
											<input
												type="text"
												className="form-control custom-form"
												placeholder="Enter your 2FA code"
												value={otp}
												onChange={(e) => setOtp(e.target.value)}
											/>
										</div>
										<button
											className={`btn btn-success fs-13 w-100 mt-3 pt-2 pb-2 ${otp.length !== 6 ? "disabled-button" : "color-4"
												}`}
											onClick={handleVerify2FA}
											disabled={otp.length !== 6}
										>
											Enable
										</button>
										<span className="text-success">
											{" "}
											{isVerified && <p>2FA is enabled and verified</p>}{" "}
										</span>

									</>}


							</div>
						</div>
					</div>
				</div>
			</div> */}
		</Fragment>
	);
};

export default Profile;
