import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../blacklogo.png";
import { LuHome } from "react-icons/lu";
import backTop from "../../images/browser/login_bg1.png";
import backbottom from "../../images/browser/flower.png";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import { check2faStatus, getProfileDetails, loginmail } from "../../helpers/api";
import OtpInput from "react-otp-input";

function Login() {
  const [twofaenabled, settwofaenabled] = useState(false)
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // helpers/validators.js
  function isValidEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function handleLogin() {
    if (!email) {
      toast.error("Enter Userid");
      return;
    }

    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    const fdata = {
      email: email
    }
    check2faStatus(fdata).then((res) => {
      settwofaenabled(res.twoFactorEnabled)
    })
    setLoading(true);
    const formData = { userId: email, password: password,twoFactorCode:twoFactorCode };

    loginmail(formData)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              userId: res?.data?.userId,
              email: res?.data?.email,
            })
          );
          navigate("/logindashboard");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred");
      });
  }
  useEffect(() => {

    const fdata = {
      userId: email
    }
    check2faStatus(fdata).then((res) => {
      settwofaenabled(res.twoFactorEnabled)
    })
 
   
  }, [email])


  return (
    <>
      <div className="page_content gray_bg">
        <div className="container login_page_container">
          <div className="login_header">
            <div>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="mCS_img_loaded"
                  height={50}
                />
              </Link>
            </div>
            <div className="d-flex gap-3">
              <Link to="/">
                <div className="short_icon_outline">
                  <LuHome />
                </div>
              </Link>
            </div>
          </div>

          <main className="page_wrapper">
            <div className="side_img1">
              <img src={backTop} alt="background top" />
            </div>
            <div className="login-card">
              <h2 className="login_title mb-1">Login Here</h2>
              <p className="text_gray">Please login or sign up to continue</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="input-icon">
                  <label className="text-dark">User Id *</label>
                  <input
                    type="text"
                    id=""
                    name="userId"
                    className="form-control"
                    placeholder="Your UserID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toUpperCase())}
                    required
                  />
                </div>
                <div className="input-icon">
                  <label className="text-dark">Password*</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                {twofaenabled ?
                  <div class="col-md-12">
                    <div class="mb-2 mt-3">
                      <label class="form-label text-white fw-bolder">
                        2FA Code
                      </label>
                      <input
                        type="text"
                        class="form-control custom-form"
                        onChange={(e) => setTwoFactorCode(e.target.value)}
                        value={twoFactorCode}
                        placeholder="Enter 2FA Code"
                      />
                    </div>
                  </div> : null}
                <div className="text-end">
                  <Link className="anchor_link" to="/Forgetpassword">
                    Forgot Password
                  </Link>
                </div>
                <div className="d-grid mb-1 mt-5 text-center">
                  <button
                    type="button"
                    className="default-btn banner-btn"
                    onClick={handleLogin}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Login Here"
                    )}
                  </button>
                </div>
                {/*   <p className="text-center">or</p>
          <div className="d-grid">
                  <div className="custom_btn_outline rounded_sm align-items-center d-flex justify-content-center">
                    <FcGoogle className="fs-5 me-1" />
                    <div>Google</div>
                  </div>
                </div> */}
                <div className="text-center text_gray fs-12 py-3">
                  Don't have an account?{" "}
                  <Link className="anchor_link" to="/register">
                    Create account
                  </Link>
                </div>
              </form>
            </div>
            <div className="side_img2">
              <img src={backbottom} alt="background bottom" />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Login;
