import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./tree.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getUserDetailsByWallet,
  getTreeView,
  detailByEmail,
  getDownlineTeam,
} from "../../../helpers/api";
import { Loader } from "../Dashboard/Loader";
import logo from "../../../dmt1.png";
import moment from "moment";
import { FaRegUser, FaUser, FaUserAlt } from "react-icons/fa"; // Import the icon
import { Link } from "@mui/material";
const MAX_LEVELS = 6; // Limit levels to 6

const TreeStructure = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const { userToken } = useSelector((action) => action.auth);
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [apidata, setApidata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([walletAddress]);
  const [userId, setUserId] = useState("");
  const sUid = userDetails?.userId;
  async function getLevelData(address, ar, packageId) {
    if (ar.length > MAX_LEVELS) {
      console.warn("Reached maximum level depth");
      return;
    }

    var treedata = {
      name: "",
      children: [],
    };

    const apidata = await getUserDetailsByWallet(address, Number(packageId));

    treedata.dateofJoining = apidata?.userDetails?.createdAt;
    treedata.direct = apidata?.userDetails?.direct_member;
    treedata.id = apidata?.userDetails?.userId;
    treedata.team_count = apidata?.userDetails?.team_count;
    treedata.totalIncome = apidata?.userDetails?.totalIncome;
    treedata.referrerId = apidata?.userDetails?.referrerId;
    try {
      getTreeView(address, packageId).then(async (res) => {
        if (res?.length > 0) {
          await Promise.all(
            res.map(async (item) => {
              // Use Promise.all for better async handling
              const apidata = await getUserDetailsByWallet(
                item?.place_referrer,
                Number(packageId)
              );

              const obj = {
                name: item?.userId?.toString(),
                placeId: apidata?.userDetails?.userId, // Assign the place referrer userId
                children: [], // Initialize an empty array for children
                ...item, // Spread remaining properties from the item
              };

              obj.children.push({ ...obj });
              treedata.children.push(obj);
            })
          );
          treedata.children.sort((a, b) => a.position - b.position);
          setApidata([{ ...treedata }]);
          setLoading(true);
        } else {
          setLoading(true);
          setApidata([{ ...treedata, children: [] }]);
        }
      });
    } catch (e) {
      setLoading(true);
      setApidata([{ ...treedata, children: [] }]);
    } finally {
      if (ar) {
        setSteps(ar);
      }
    }
  }
  useEffect(() => {
    setSteps(1);
    getLevelData(walletAddress, [], userToken?.value);
  }, [walletAddress, userToken]);
  const handleNodeClick = (chl) => {
    if (steps.length < MAX_LEVELS) {
      const ar = [...steps, chl.user];
      console.log(ar, " user addr");
      getLevelData(chl.user, ar, userToken?.value);
    }
  };

  const tooltip = (chl) => (
    <Tooltip id="multi-api-tooltip">
      <strong>Team Count:</strong> {chl?.team_count} <br />
      <strong>Total Income:</strong> {chl?.totalIncome} <br />
      <strong>Date of Joining:</strong>{" "}
      {moment(chl?.dateofJoining).format("DD-MM-YYYY")} <br />
      <strong>Place Referral :</strong> {chl?.placeId} <br />
    </Tooltip>
  );

  const tooltipMain = (item) => (
    <Tooltip id="multi-api-tooltip">
      <strong>Team Count:</strong> {item?.team_count} <br />
      <strong>Total Income:</strong> {item?.totalIncome} <br />
      <strong>Date of Joining:</strong>{" "}
      {moment(item?.dateofJoining).format("DD-MM-YYYY")} <br />
      <strong>Referral Id:</strong> {item?.referrerId} <br />
    </Tooltip>
  );

  async function searchById(id) {
    const formData = {
      packageId: userToken?.value,
      userId: id,
    };

    const fData = {
      userId: sUid,
      packageId: userToken?.value,
    };

    try {
      // Fetch downline team data
      const downlineRes = await getDownlineTeam(fData);
      console.log(downlineRes, "downlineRes:::");
      const matchingUser = downlineRes?.uplines?.find(
        (upline) => upline._id.userId === id
      );

      if (matchingUser) {
        console.log(`User ID ${id} found in downline:`, matchingUser);

        // Call detailByEmail if user is found in downline
        const res = await detailByEmail(formData);
        if (res?.data?.user) {
          setUserId("");
          await getLevelData(res.data.user, [], userToken.value);
        }
      } else {
        console.log("No data found: User ID not in downline team");
      }
    } catch (e) {
      console.error("Error searching by ID:", e);
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <section className="pb-5" style={{ height: "100%" }}>
          {!loading ? (
            <Loader />
          ) : (
            <div>
              <div className="row align-item-center">
                <div className="col-lg-8">
                  {" "}
                  <h3 className="heading mb-0">Your Level Tree</h3>
                </div>

                <div className="col-lg-4">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="View by User Id..."
                      value={userId}
                      className="form-control"
                      onChange={(e) => setUserId(e.target.value.toUpperCase())}
                    />

                    <Link className="input-group-text bg-dgreen text-decoration-none">
                      <div onClick={() => searchById(userId)}>
                        <span className="text-white ">Submit</span>
                      </div>
                    </Link>
                  </div>
                </div>
                </div>
                <div className="d-flex justify-content-between my-5">
                  <h3 className="text-warning fw-bold mb-0">
                    Level {steps?.length ? steps?.length : 1}
                  </h3>

                  <div>
                    <button
                      className="btn-action me-1"
                      onClick={async () => {
                        let ar = [...steps];
                        console.log(ar, "ar::::");
                        if (ar.length > 1) {
                          ar.pop();
                          const previousAddress = ar[ar.length - 1];
                          setSteps(ar);
                          await getLevelData(
                            previousAddress,
                            ar,
                            userToken?.value
                          );
                        } else {
                          setSteps([walletAddress]);
                          await getLevelData(
                            walletAddress,
                            [walletAddress],
                            userToken?.value
                          );
                        }
                      }}
                    >
                      {"Go Level Back"}
                    </button>
                    <button
                      className="btn-action"
                      onClick={() =>
                        getLevelData(
                          walletAddress,
                          [walletAddress],
                          userToken?.value,
                          3
                        )
                      }
                    >
                      {"Root"}
                    </button>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="tree col-lg-12 col-md-12 mx-auto treresp">
                    {apidata?.length > 0 &&
                      apidata.map((item) => (
                        <ul
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          key={item.id}
                        >
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltipMain(item)}
                            >
                              <a
                                href="#"
                                className="custom-style"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  border: "0.3px solid rgb(220 247 221)",
                                        background:"#00b09b"
                                }}
                              >
                                <div>
                                  <img src={logo} alt="" width={50} />
                                  <p
                                    className="text-center text-white pt-1"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {item?.id}
                                  </p>
                                </div>
                              </a>
                            </OverlayTrigger>
                            <ul className="subtree table-responsive pb-3">
                              {item?.children.map((chl) => (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip(chl)}
                                  key={chl?.userId}
                                >
                                  <li onClick={() => handleNodeClick(chl)}>
                                    <a
                                      href="#"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        border: "0.3px solid rgb(220 247 221)",
                                        background:"#a4e3a724"
                                      }}
                                    >
                                      <div className="p-0">
                                        <img src={logo} alt="" width={50} />
                                        <p
                                          className="text-center  pt-1"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {chl?.userId}
                                        </p>
                                      </div>
                                    </a>
                                  </li>
                                </OverlayTrigger>
                              ))}
                              {item?.children.length < 4 &&
                                [...Array(4 - item.children.length)].map(
                                  (_, index) => (
                                    <li key={index}>
                                      <a
                                        href="#"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          border:
                                            "0.3px solid rgb(249 249 249)",
                                          background: "rgb(250 250 250)",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div className="vacant-slot">
                                          {/* Use the static icon for vacant slots */}
                                          <FaRegUser
                                            size={40}
                                            color="#efefef"
                                          />
                                        </div>
                                      </a>
                                    </li>
                                  )
                                )}
                            </ul>
                          </li>
                        </ul>
                      ))}
                  </div>
                </div>
             
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default TreeStructure;
