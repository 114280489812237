import React, { useEffect, useState, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment/moment";
import { Pagination } from "@mui/material";

import {
  getDirectMember,
  getDirectTeam,
  getUserTeam,
} from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
const MyTeam = () => {
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const { userToken } = useSelector((action) => action.auth);
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleString('en-GB', options); // This outputs "DD/MM/YYYY, HH:MM:SS"
  };
  useEffect(() => {
    getUserTeam(walletAddress,userToken.value).then((res) => {
     
      setApidata(res?.downline);
      setLoading(true);
    });
  }, [page, walletAddress,userToken]);

  return (
    <>
      {!loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">My Downline Team</h4>
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr className="">
                            {/* <th className="">Txn Hash</th> */}
                            <th className="">User Address</th>
                            <th className=""> User Id</th>

                            <th className="">
                              Total Received Donation ($)
                            </th>
                            <th className="">
                              Total Team 
                            </th>
                            <th className=""> Joined On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {apidata?.length > 0 ? (
                            apidata?.map((e, index) => (
                              <tr key={index}>
                            
                                <td>
                                  {" "}
                                  {e?.user?.slice(0, 6)}...
                                  {e?.user?.slice(-5)}
                                </td>
                                <td>{e?.userId}</td>
                                <td> {e?.totalIncome}</td>
                                <td> {e?.team_count}</td>
                                <td>
                                  {" "}
                                  {formatDate(e?.createdAt)}
                                  {/* {moment((e.createdAt)).fromNow()} */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                  No Data Found.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                   
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyTeam;
